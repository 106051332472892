
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { message } from 'ant-design-vue'
import { useAuthStore } from '@/store/auth'
import api from '@/apis'

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const device = createDeviceDetector();

    /* Store */
    const authStore = useAuthStore();

    /* Loading */
    const submitting = ref<boolean>(false);

    /* Form State */
    const formState = reactive({
      identifier: '',
      credential: '',
      role: 2
    });

    /* Mounted */
    onMounted(async () => {
      try {
        const response = await api.v1.auth.checkLogin();
        authStore.setInfo({
          info: response.data.result.info
        });

        message.success('歡迎回來老玩客熟齡運動管理平台');
        toStartedPage(response.data.result.role);
      } catch (error) {
        console.log(error)
      }
    });

    /* Router Func */
    const toStartedPage = (role: number) => {
      if (role == 4) {
        router.push({ name: 'AppMembers' });
      } else if (role == 3) {
        router.push({ name: 'AppCourses' });
      } else if (role == 2) {
        router.push({ name: 'AppCourses' });
      }
    }
    
    const roleOptions = ref([
      {
        value: 4,
        label: '管理員'
      },
      {
        value: 3,
        label: '助教'
      },
      {
        value: 2,
        label: '教練'
      },
    ]);

    return {
      device,
      api,

      /* Store */
      authStore,

      /* Loading */
      submitting,
      
      /* Form State */
      formState,
      roleOptions,

      /* Router Func */
      toStartedPage
    }
  },
  methods: {
    /* Private Func */
    _checkRequired() {
      let err = false;
      if (!this.formState.identifier) {
        err = true;
        message.error('請輸入帳號');
      }
      else if (!this.formState.credential) {
        err = true;
        message.error('請輸入密碼');
      }
      return err
    },

    /* Api */
    async login() {
      const err = this._checkRequired();
      if (err) return

      this.submitting = true;

      try {
        const response = await api.v1.auth.login({
          identifier: this.formState.identifier,
          credential: this.formState.credential,
          role: this.formState.role
        });

        this.authStore.setInfo({
          info: response.data.result.info
        });
        message.success('登入成功');

        // 根據權限分流至不同初始頁面
        this.toStartedPage(response.data.result.role);
      } catch (error) {
        message.error('登入失敗，請確認您的帳號及密碼是否正確');
        console.log(error);
      } finally {
        this.submitting = false;
      }
    }
  }
})
